<template>
	<div class="change-box">
		<div class="content-title">商户信息</div>
		<el-row>
			<el-col :span="6" class="item-title">商户编号</el-col>
			<el-col :span="12" class="item-value" v-if="!this.isAdd">
				{{ statusData.merchantNo }}
			</el-col>
			<el-col :span="12" v-else>
				<el-input
					v-model="statusData.merchantNo"
					style="width:300px; margin-right:10px"
				></el-input>
				<el-button
					type="primary"
					@click="getStastus"
					v-permission="'MER_CHANGE_APPLY_INFO_INSERT'"
					>查询</el-button
				>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">商户名称</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.merSubName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">账户类型</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.accountType
			}}</el-col>
			<el-col :span="3" class="item-title">开户名</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.accountName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算账号</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.accountNo
			}}</el-col>
			<el-col :span="3" class="item-title">开户行</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.bankName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">开户地区</el-col>
			<el-col :span="6" class="item-value"
				>{{ statusData.accountProvince | provinceCityFM
				}}{{
					statusData.accountCity
						| provinceCityFM(statusData.accountProvince)
				}}</el-col
			>
			<el-col :span="3" class="item-title">开户支行</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.branchName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算人身份证号</el-col>
			<el-col :span="12" class="item-value">
				{{ maskIDNumber(statusData.settleIdCard) }}
			</el-col>
		</el-row>
		<br />
		<div class="content-title">变更申请</div>
		<el-form
			ref="saveDataform"
			:model="saveData"
			:rules="rules"
			label-width="0"
		>
			<div class="images-box">
				<el-form-item prop="accountImg" style="margin:0 auto">
					<div class="images-item">
						<div class="images-uploader">
							<OssUpload
								@change="getAccountImg"
								:oldImg="saveData.accountImg"
								suffix="gif;jpg;png;jpeg"
								ocrType="BANK_CARD"
								:isOneImg="true"
							></OssUpload>
						</div>
						<div class="images-title">
							<span>*</span>结算卡照片（带卡号面）
						</div>
					</div>
				</el-form-item>
			</div>
			<el-row>
				<el-col :span="6" class="item-title">开户名</el-col>
				<el-col :span="6" class="item-value">{{
					statusData.accountName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">结算人身份证号</el-col>
				<el-col :span="12" class="item-value">{{
					maskIDNumber(statusData.settleIdCard)
				}}</el-col>
			</el-row>

			<el-row>
				<el-col :span="6" class="item-title">结算账号</el-col>
				<el-col :span="12">
					<el-form-item prop="accountNo">
						<el-input v-model="saveData.accountNo"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户行</el-col>
				<el-col :span="12">
					<el-form-item prop="bankName">
						<el-select
							style="width:400px"
							v-model="saveData.bankName"
							filterable
							remote
							reserve-keyword
							placeholder="请输入开户银行"
							:remote-method="remoteMethod"
							@change="getBank"
							:loading="loading"
						>
							<el-option
								v-for="item in options"
								:key="item.id"
								:label="item.name"
								:value="item"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户地区</el-col>
				<el-col :span="12">
					<div style="float:left">
						<el-form-item prop="accountProvince">
							<el-select
								v-model="accountProvinceName"
								placeholder="请选择省份"
								@change="provinceChange"
							>
								<el-option
									v-for="item in provinceList"
									:key="item.code"
									:label="item.name"
									:value="item"
								></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div style="float:left; padding:0 10px" :span="1">-</div>
					<div style="float:left">
						<el-form-item prop="accountCity">
							<el-select
								v-model="saveData.accountCity"
								placeholder="请选择城市"
							>
								<el-option
									v-for="item in cityList"
									:key="item.code"
									:label="item.name"
									:value="item.code"
								></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户支行</el-col>
				<el-col :span="12">
					<el-form-item prop="branchName">
						<el-select
							style="width:400px"
							@focus="checkPC"
							v-model="saveData.branchName"
							filterable
							remote
							reserve-keyword
							placeholder="请输入支行名称"
							:remote-method="branchRemoteMethod"
							@change="getCnaps"
							:loading="loading"
						>
							<el-option
								v-for="item in branchOptions"
								:key="item.id"
								:label="item.name"
								:value="item"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<br />
		<el-row :gutter="10" class="mb8">
			<el-col :span="8" class="item-title"> </el-col>
			<el-col :span="6">
				<el-button
					type="primary"
					@click="submit"
					v-permission="'MER_CHANGE_APPLY_INFO_INSERT'"
				>
					申请
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { MerchantApi, CommonApi } from "@/api";
import { mapState } from "vuex";
export default {
	data() {
		var checkAccountImg = (rule, value, callback) => {
			if (!this.saveData.accountImg) {
				return callback(new Error("请上传结算卡照片"));
			} else {
				value = this.saveData.accountImg;
				callback();
			}
		};
		return {
			statusData: {
				merchantNo: "",
				accountProvince: "",
				accountCity: ""
			},
			saveData: {
				accountProvince: "",
				accountCity: "",
				branchName: "",
				bankName: ""
			},
			accountProvinceName: "",
			checked: true,
			isAdd: true,
			applyNo: "",
			cityList: "",
			branchOptions: [],
			options: [],
			rules: {
				accountProvince: [
					{ required: true, message: "请选择省", trigger: "change" }
				],
				accountImg: [{ validator: checkAccountImg, trigger: "change" }],
				accountCity: [
					{ required: true, message: "请选择市", trigger: "change" }
				],
				accountNo: [
					{
						required: true,
						message: "请输入结算账号",
						trigger: "blur"
					}
				],
				bankName: [
					{ required: true, message: "请输入开户行", trigger: "blur" }
				],
				branchName: [
					{ required: true, message: "请选择支行", trigger: "change" }
				]
			}
		};
	},
	created() {
		this.isAdd = this.$route.query.isAdd ? true : false;
		this.applyNo = this.$route.query.applyNo;
		if (!this.isAdd) {
			MerchantApi.merChangeApply
				.queryChangeApplyDetail(this.applyNo)
				.then(res => {
					if (res.success) {
						this.statusData = res.data.merSettleChangeApplyDetail;
					}
				});
		}
	},
	computed: {
		...mapState("app", ["provinceCityArea"]),
		provinceList() {
			return "" || this.provinceCityArea;
		}
	},
	methods: {
		getAccountImg(fileList, filename, ocrData) {
			if (JSON.stringify(ocrData) != "{}") {
				this.$set(
					this.saveData,
					"accountNo",
					ocrData.bank_card_number.replace(/\s*/g, "")
				);
				this.$set(this.saveData, "bankName", ocrData.bank_name);
				if (ocrData.bank_name) {
					this.remoteMethod(ocrData.bank_name, "ocr");
				}
			}
			this.saveData.accountImg = filename;
		},

		submit() {
			if (!this.statusData.settleIdCard) {
				this.Message.error("请先查询商户编号");
				return;
			}
			this.$refs.saveDataform.validate(valid => {
				if (valid) {
					MerchantApi.merSettleChangeApplyDetail
						.merSettleChangeApply(
							this.statusData.merchantNo,
							this.saveData
						)
						.then(res => {
							if (res.success) {
								this.Message.success("申请成功");
								this.$router.push({
									name: "merchantChangeApply"
								});
							}
						});
				} else {
					return false;
				}
			});
		},
		async getStastus() {
			if (!this.statusData.merchantNo) {
				this.Message.error("请输入商户编号");
				return;
			}
			let result = await MerchantApi.merSettleChangeApplyDetail.queryMerSettleChangeApply(
				this.statusData.merchantNo
			);
			result.success
				? (this.statusData = result.data)
				: this.Message.error(result.message);
		},
		provinceChange(val) {
			this.saveData.accountCity = "";
			this.accountProvinceName = val.name;
			this.saveData.accountProvince = val.code;
			this.cityList = val.children;
		},
		async remoteMethod(query, ocr) {
			if (query != "") {
				let res = await CommonApi.bankList({ name: query });
				if (res.success) {
					this.options = res.data;
					if (ocr) {
						this.saveData.bankName = this.options[0].name;
						this.saveData.bankCode = this.options[0].code;
						this.saveData.branchName = "";
						this.saveData.cnapsNo = "";
						this.branchOptions = [];
					}
				}
			} else {
				this.options = [];
			}
		},
		async branchRemoteMethod(query) {
			if (query != "") {
				let params = {
					provinceCode: this.saveData.accountProvince,
					cityCode: this.saveData.accountCity,
					bankCode: this.saveData.bankCode,
					name: query
				};
				let res = await CommonApi.branchList(params);
				if (res.success) {
					this.branchOptions = res.data;
				}
			} else {
				this.branchOptions = [];
			}
		},
		checkPC() {
			if (!this.saveData.accountCity) {
				this.Message.error("开户行城市不能为空");
				return;
			}
			if (!this.saveData.bankName) {
				this.Message.error("开户银行不能为空");
				return;
			}
		},
		getCnaps(e) {
			this.saveData.branchName = e.name;
			this.saveData.cnapsNo = e.code;
		},
		getBank(e) {
			this.saveData.bankName = e.name;
			this.saveData.bankCode = e.code;
			this.saveData.branchName = "";
			this.saveData.cnapsNo = "";
			this.branchOptions = [];
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		}
	}
};
</script>
